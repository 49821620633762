@use 'styles/vars' as *;
@use 'styles/global/text' as *;
@use 'styles/mixins/rich-text';
@use "sass:color";
@import '~react-quill/dist/quill.snow.css';


.ql-toolbar.ql-snow {
  font-family: $ff-text;
  padding: 0 $half-spacing;
  border: none;
}

.simple-editor .ql-toolbar.ql-snow {
  padding: 0;
  border-radius: $default-border-radius $default-border-radius 0 0;
  background-color: $colors-grey-medium;
}

.ql-snow.ql-toolbar button {
  float: none;
  padding: 0;
  height: 20px;
  width: auto;
  margin: $quarter-spacing;
}

.ql-toolbar.ql-snow .ql-formats {
  padding: $half-spacing;
  display: flex;
  margin-right: $half-spacing;

  @include mobile-down() {
    padding: $half-spacing 0;
  }
}

.simple-editor .ql-toolbar.ql-snow .ql-formats {
  padding: 0;
}

.ql-snow.ql-toolbar button.u-mobile-up-only,
.ql-formats.u-mobile-up-only {
  @include mobile-down() {
    display: none;
  }
}

.ql-editor {

  > :first-child {
    margin-top: 0;
  }

  min-height: $content-spacing * 15;

  h1 {
    margin: $fs-large 0;
  }

  h2 {
    margin: $quarter-spacing 0;
  }
}

.simple-editor .ql-editor {
  min-height: $content-spacing * 4;
}

.ql-container {
  border-radius: $default-border-radius $default-border-radius;
}

.ql-container.ql-snow {
  border: none;
}

.simple-editor .ql-container.ql-snow {
  border: 1px solid $colors-grey-medium;
  border-radius: 0 0 $default-border-radius $default-border-radius;
  background-color: $colors-white;
}

.ql-editor.ql-blank {
  width: 100%;
}

.ql-editor.ql-blank::before {
  @extend .text-richtext;
  font-style: normal;
  color: $colors-grey-dark;
  line-height: 1.5;
}

.ql-toolbar-hidden {
  display: none;
}

.ql-snow .ql-editor {
  @extend .text-richtext;
  @include break-words();
  @include rich-text.quillContent();
  @include rich-text.quillNestedLists();
}

.ql-rich-text {
  @extend .text-richtext;
  white-space: pre-line;
  word-break: break-word;
  @include break-words();
  @include rich-text.quillContent();
  @include rich-text.quillNestedLists();

  p.ql-custom-paragraph {
    margin-bottom: $fs-medium;
  }
}

.ql-image-grid-3 {
  display: grid;
  grid-template-columns: 1fr;

  img {
    width: 100%;
    max-height: 400px; // these are MC specific values to make their images look good
    object-fit: contain; // these are MC specific values to make their images look good
  }


  @include tablet-up() {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }

}

.ql-rich-text-mc {
  p a {
    @extend .text-link-mc;
  }
}

p.ql-align-right {
  text-align: right;
}

p.ql-align-center {
  text-align: center;
}

@for $i from 1 through 8 {
  .ql-indent-#{$i} {
    padding-left: 3rem * $i;
  }
}

.iframely {
  margin: $content-spacing 0;
}

.iframely-responsive,
.embed-responsive {
  top: 0;
  left: 0;
  width: 100% !important;
  height: 0 !important;
  position: relative;

  border: 1px solid $colors-grey-light;

  iframe {
    width: 100% !important;
    height: 100% !important;
  }
}

.iframely-padding {
  padding-bottom: 56.25%;
}

.crowdworks-padding {
  padding-bottom: 48%;
}

.embed-responsive {
  border-left: none;
  border-right: none;
}

.iframely-responsive>*,
.embed-responsive>* {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border: 0;
}

p.ql-custom-paragraph {
  .mention {
    background-color: inherit;
    margin-right: 0;
    @extend .text-link;

    span {
      margin: 0;
    }
  }

  img {
    border-radius: $default-border-radius;
    margin: $content-spacing 0;
  }
}

.ql-mention-denotation-char {
  display: none;
}

.ql-mention-list {
  .ql-mention-list-item {
    padding: $half-spacing;
    @include line-height('body');

    &.selected {
      background-color: $colors-grey-light;
    }
  }
}

.ql-styled-mention-container {
  display: flex;

  .ql-styled-mention-avatar {
    width: 36px;
    height: 36px;
    background-color: white;
    border-color: white;
    border-radius: 100%;

    img {
      border-radius: 100%;
    }
  }
}

.ql-snow .ql-editor img {
  max-height: 600px; // 600px supports 16:9 and 4:3 aspect ratios on 800px wide content
  width: auto;
}

.ql-snow .ql-tooltip {
  font-weight: $fw-medium;
  border: none;
  font-family: $ff-text;
  color: $colors-indigo;
}

.ql-snow .ql-tooltip input[type='text'] {
  border-radius: $default-border-radius;
  border-color: $colors-grey-medium;
}

.ql-snow .ql-picker-label,
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  font-weight: $fw-regular;
  display: flex;
  border: none;
}

.ql-snow .ql-picker-options,
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-radius: $default-border-radius;
  padding: 4px 10px;
  border-color: #fff;
}

.ql-picker-item {
  font-weight: $fw-regular;
}

.ql-picker-item[data-value='1'],
.ql-picker-item[data-value='2'] {
  font-weight: $fw-medium;
  font-family: $ff-text;
}

.ql-picker-item[data-value='1'] {
  &::before {
    font-size: $fs-x-large !important;
  }
}

.ql-picker-item[data-value='2'] {
  &::before {
    font-size: $fs-medium !important;
  }
}

.ql-mention-divider {
  padding: 0 $quarter-spacing;
  font-family: $ff-text;
  font-weight: $fw-regular;
  font-size: $fs-small;
  color: $colors-grey;
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 100%;

  &::after {
    content: '';
    height: 1px;
    width: 100%;
    background-color: $colors-grey;
    margin-left: $half-spacing;
  }
}

.ql-snow .ql-editor ol li {
  margin-left: $quarter-spacing;
  list-style-position: outside;
}

#postEditorContainer {
  p.ql-custom-paragraph {
    margin-bottom: $fs-medium;
  }
}