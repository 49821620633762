@use 'styles/vars' as *;

.logo {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  position: relative;
  margin: $content-spacing 0;

  @include mobile-up() {
    min-width: 150px;
    flex-basis: 20%;
    margin-right: $section-spacing;
    margin-bottom: 0;
    padding-right: $section-spacing;
  }
}

.logoPlaceholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.bannerActions {
  display: flex;
  align-items: center;
  margin-top: $content-spacing;

  @include tablet-down() {
    flex-direction: column;
    align-items: flex-start;
    gap: $content-spacing;
  }
}